.MB-logo{
    height: 18%;
    display: flex;
    width: 30%;
    align-items: start;
    justify-content: start;
}

.MB-logo-letter{
    height: 100%;
    display: flex;
    align-items: end;
}

.MB-logo-letter img:not(.first-letter){
    margin-left: 3px;
}

.MB-logo-letter img{
    height: 69%;
}


@media screen and (max-width: 1700px)
{
    .MB-logo{
        height: 100%;
    }

}