@font-face {
    font-family: 'Druk-Wide';
    src: url(../assets/font/Druk-Wide-Bold.ttf);
}

@font-face {
    font-family: 'Monstserrat';
    src: url(../assets/font/montserrat/Montserrat-Regular.ttf);
}

.MB-data{
    color: white;
    display: flex;
    justify-content: end;
    margin-right: 2%;
    margin-top: 10vw;
}

.MB-data ul{
    padding: 0;
}

.MB-contente-data{
    display: flex;
    margin-bottom: 40px;
    font-family: 'Monstserrat';
}
.data-name{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    color: #3F3E44; /*vérifier la font*/
    font-size: 18px;
}

.MB-contente-data div{
    font-size: 12px;
    text-align: start;
}

.MB-contente-data div p{
    
    margin: 10px 0px 10px 0px;
}
.data-h1{
    font-size: 32px;
    margin: 0px;
    font-family: 'Druk-Wide';
}

@media screen and (max-width: 1700px)
{
    .MB-data ul{
        display: flex;
        width: 70vw;
        justify-content: space-around;
    }

    .MB-data{
        margin-top: 7vw;
        margin-bottom: 8vw ;
    }
}

@media screen and (max-width: 1000px)
{
    .MB-data ul{
        flex-wrap: wrap;
        justify-content: start;
    }
}

